.main-footer {
    position: fixed;
    bottom:0px;
    width:100%;
}

button {
    margin-left: 5px;
}

.table-actions {
    width: 150px;
}

.map-marker {
	margin-top: -30px;
	font-weight: bold;
}

@keyframes piscar {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
}
  
.piscar {
    animation: piscar 1s infinite; /* 1s é a duração da animação, ajuste conforme necessário */
    font-size: 18px;
}